<template>
<div class="bills-content">
    <a-card class="mt-2">
        <div class="d-flex justify-content-between align-items-center mb-4">
            <span class="h5 mb-0"><font-awesome-icon icon="money-check-alt"
                                                     class="mt-1 mr-1 font-size-24"/> {{ $t('common.projectDeposits') }}</span>
        </div>

        <!-- First row -->
        <div class="d-flex mb-3">
            <!-- Select project -->
            <div>
                <a-select v-model="projectId" placeholder="Project" class="width-150" @change="updateTable">
                    <a-select-option :value="0">
                        [{{ $t('pre.all') }} {{ $t('common.projects') }}]
                    </a-select-option>
                    <a-select-option v-for="project in activeProjectList" :key="project.id">
                        <a-badge status="success" v-if="project.is_active"/>
                        <a-badge color="grey" v-else/>
                        {{ project.name }}
                    </a-select-option>
                </a-select>
            </div>
            <!-- Select project -->
            <!-- Select date -->
            <div class="ml-3">
                <a-range-picker
                    v-model="datetime"
                    format="YYYY-MM-DD"
                    :allowClear="false"
                    @change="updateTable"
                />
            </div>
            <!-- Select date -->
            <!-- Select search -->
            <div class="" style="margin-left: auto;">
                <a-input-search
                    v-model="searchValue"
                    :placeholder="$t('placeholders.selectSearch')"
                    style="width: 300px"
                    allowClear
                    @search="updateTable"
                >
                </a-input-search>
            </div>
            <!-- Select search -->
        </div>
        <!-- First row -->
        <!-- Second row -->
        <div class="mt-1 mb-3">
            <a-row :gutter="16" type="flex" align="bottom">
                <a-col :span="4">
                  <a-statistic :title="`${$t('common.total')} ${$t('common.purchases')}`" :value="statData.count"
                               style="margin-right: 50px">
                  </a-statistic>
                </a-col>
                <a-col class="d-flex">
                  <a-statistic :title="`${$t('common.sum')} USD`" :value="statData.sum_usd ? statData.sum_usd : 0"
                               prefix="$" style="margin-right: 50px">
                  </a-statistic>
                  <a-statistic :title="`${$t('common.sum')}`" :value="amountOfCurrencies"
                               style="margin-right: 50px">
                  </a-statistic>
                </a-col>
                <a-col style="margin-left: auto;" display="flex">
                    <!-- Export button -->
                    <a-button :disabled="reportCSVLoading" @click="createReportCSV" type="primary">
                        <a-icon type="file-excel"/>
                    </a-button>
                    <!-- Export button -->
                    <!-- Reload button -->
                    <a-button :loading="tableLoading" @click="updateTable" class="ml-2">
                        <a-icon type="reload"/>
                    </a-button>
                    <!-- Reload button -->
                </a-col>
            </a-row>
        </div>
        <!-- Second row -->

        <a-table
            :columns="columns"
            :dataSource="depositsData"
            :row-key="record => record.id"
            :pagination="pagination"
            :loading="tableLoading"
            @change="handleTableChange"
        >
            <template v-slot:created_at="created_at">
                {{ $moment.parseZone(created_at).format('DD.MM.YY') }}
                <span class="small d-block text-gray-5"><a-icon
                    type="clock-circle"/> {{ $moment.parseZone(created_at).format('HH:mm') }}</span>
            </template>
            <template v-slot:id="id, record">
                #{{ id }}
                <span class="d-block text-gray-5">
            UUID: {{ record.uuid.substr(0, 10) }}..
            <a-tooltip :title="$t('tooltips.copyToClipboard')" placement="right" class="text-gray-5">
              <a href="javascript:"><a-icon type="copy" @click="clipboardHandle(record.uuid)"/></a>
            </a-tooltip>
          </span>
                <span class="d-block text-gray-5">
            extID: {{ (record.ext_id.length > 10) ? record.ext_id.substr(0, 10) + '..' : record.ext_id }}
            <a-tooltip :title="$t('tooltips.copyToClipboard')" placement="right" class="text-gray-5">
              <a href="javascript:"><a-icon type="copy" @click="clipboardHandle(record.ext_id)"/></a>
            </a-tooltip>
          </span>
            </template>
            <template v-slot:project="project">
          <span v-if="project">
            <span v-if="project.logo_url">
              <img :src="project.logo_url" width="18"/>
            </span>
            {{ project.name }}
          </span>
                <span v-else>-</span>
            </template>
            <template v-slot:amount="amount, record">
          <span v-if="amount">
            {{ amount }} USDT
            <span v-if="record.from_amount" class="d-block text-gray-5">
              <a-icon type="retweet"/> {{ record.from_amount }}
              <span>{{ record.from_currency.abbr }}</span>
              <span class="ml-1 small">| {{ record.from_rate }}</span>
            </span>
            <span class="d-block mt-2">
              <span v-if="record.is_succeeded === true"><a-icon type="check" class="text-success" /></span>
              <span v-else><a-icon type="warning" class="text-warning" /></span>
              <span v-if="record.transferred && record.transferred.to_client" class="ml-1">
                <a-tooltip title="The balance for this operation was transferred to another account" placement="bottom">
                  <a href="javascript:" @click="showTransfer(record.transferred)">
                    <a-tag><a-icon type="arrow-right"/> transferred</a-tag>
                  </a>
                </a-tooltip>
              </span>
              <span v-else class="ml-1 small text-gray-5">Not transferred</span>
            </span>
          </span>
            </template>
            <template v-slot:client="client">
          <span v-if="client">
            <span v-if="client.login">
              {{ (client.login.length > 10) ? client.login.substr(0, 10) + '..' : client.login }}
            </span>
            <span class="d-block mt-1">
              <span v-if="client.phone" class="text-gray-5">
                <a-icon type="phone"/> {{ '**' + client.phone.slice(-4) }}
                <a-tooltip :title="$t('tooltips.copyToClipboard')" placement="right">
                  <a href="javascript:" class="text-gray-5"><a-icon type="copy" @click="clipboardHandle(client.phone)"/></a>
                </a-tooltip>
              </span>
              <span v-else class="text-gray-4"><a-icon type="phone"/> unavailable</span>
            </span>
            <span class="text-gray-5 d-block small mt-1">
              id: {{ client.id }}
              <a-tooltip :title="$t('tooltips.copyToClipboard')" placement="right" class="text-gray-5">
                <a href="javascript:"><a-icon type="copy" @click="clipboardHandle(client.id.toString())"/></a>
              </a-tooltip>
            </span>
            <span class="text-gray-5 d-block small">
              extID: {{ (client.ext_id.length > 8) ? client.ext_id.substr(0, 8) + '..' : client.ext_id }}
              <a-tooltip :title="$t('tooltips.copyToClipboard')" placement="right" class="text-gray-5">
                <a href="javascript:"><a-icon type="copy" @click="clipboardHandle(client.ext_id)"/></a>
              </a-tooltip>
            </span>
          </span>
            </template>
            <template v-slot:comment="comment">
          <span v-if="comment" class="small">
            <span v-if="comment.length > 10" class="mr-1">
              <a-tooltip :title="$t('tooltips.show')" placement="bottom">
                <a href="javascript:"><a-icon type="eye" @click="showComment(comment)"/></a>
              </a-tooltip>
            </span>
            {{ (comment.length > 10) ? comment.substr(0, 10) + '..' : comment }}
          </span>
                <span v-else>-</span>
            </template>
        </a-table>
    </a-card>
    <a-modal
        :title="`Transfer ${transferInfo && transferInfo.id ? '#' + transferInfo.id : ''} details`"
        v-model="transferModal"
        :footer="null"
        width="480px"
    >
        <div v-if="transferInfo && transferInfo.to_client">
            <h3>{{ transferInfo.amount }} $</h3>
            <p class="font-weight-bold">
                <a-icon type="arrow-right"/>
                transferred to:
            </p>
            <a-card class="mt-2">
                <p v-if="transferInfo.to_client.project">
                    <a-icon type="api"/>
                    Project: <b>{{ transferInfo.to_client.project.name }}</b>
                </p>
                <p>
                    <a-icon type="credit-card"/>
                    Balance ID: {{ transferInfo.to_balance_id }}
                </p>
                <p v-if="transferInfo.to_client.phone">
                    <a-icon type="phone"/>
                    Phone: {{ transferInfo.to_client.phone }}
                </p>
                <p>ID: {{ transferInfo.to_client.id }}<br/>
                    ExtID: {{ transferInfo.to_client.ext_id }}<br/>
                    Login: {{ transferInfo.to_client.login }}
                </p>
                <div class="mt-2" v-if="transferInfo.comment">
                    Comment:
                    <p>{{ transferInfo.comment }}</p>
                </div>
            </a-card>
        </div>
    </a-modal>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ApiService from '@/services/api/api.service'
import moment from 'moment-timezone'
import { Modal } from 'ant-design-vue'
import apiClient from '@/services/axios'

const columns = [
  {
    title: 'Created',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '10%',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'IDs',
    dataIndex: 'id',
    key: 'id',
    width: '15%',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Project',
    dataIndex: 'project',
    key: 'project',
    width: '12%',
    scopedSlots: { customRender: 'project' },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: '16%',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: 'Client',
    dataIndex: 'client',
    key: 'client',
    width: '15%',
    scopedSlots: { customRender: 'client' },
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    width: '12%',
    align: 'right',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'comment' },
  },
]

export default {
  name: 'ProjectsList',
  computed: {
    ...mapState(['user']),
    ...mapGetters(['activeProjectList']),
    searchPlaceHolder() {
      return this.$t('placeholders.selectSearch')
    },

    /**
     * This code calculates amount of all currencies without separating, just because
     * @returns {T|number}
     */
    amountOfCurrencies() {
      if (typeof this.statData.currencies !== 'undefined') {
        return this.statData.currencies.reduce((acc, currency) => acc + Number(currency.sum_from), 0)
      }
      return 0
    },
  },
  data() {
    return {
      depositsData: [],
      columns,
      moment,
      Modal,
      tableLoading: false,
      transferModal: false,
      transferInfo: undefined,
      pagination: { pageSize: 20 },
      searchValue: '',
      projectId: 0,
      statData: {
        count: 0,
        sum_amount: 0,
        sum_usd: 0,
      },
      datetime: [
        this.$moment(this.$moment().utc().startOf('month'), 'YYYY-MM-DD'),
        this.$moment(this.$moment().utc(), 'YYYY-MM-DD').endOf('day'),
      ],
      reportCSVLoading: false,
    }
  },
  mounted() {
    this.refreshList()
    this.getStatData()
  },
  methods: {
    async createReportCSV() {
      try {
        this.reportCSVLoading = true
        const url = '/admin/projects/deposits/analytics/report'
        const response = await apiClient.post(url, {
          agent_id: this.accountId,
          project_id: this.projectId,
          from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
          to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
          search: this.searchValue,
        })
        setTimeout(() => {
          const fileLink = document.createElement('a')
          fileLink.setAttribute('href', response.data.data.file_path)
          fileLink.setAttribute('download', response.data.data.file_path.replace(/^.*[\\/]/, ''))
          document.body.appendChild(fileLink)
          fileLink.click()
          document.body.removeChild(fileLink)
        }, 0)
      } catch (e) {
        this.$notification.error({
          message: 'System can`t make report',
          description: '',
        })
      } finally {
        this.reportCSVLoading = false
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        search: this.searchValue,
        from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
        project_id: this.projectId,
      })
    },
    handleSearchChanged() {
      this.searchValue = ''
    },
    handleProjectChange(e) {
      this.refreshList()
      this.getStatData()
    },
    async fetch(params = {}) {
      this.tableLoading = true
      const reqData = { results: 20, ...params }
      return ApiService.getProjectsDeposits(reqData).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.tableLoading = false
        this.depositsData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.tableLoading = false
      })
    },
    async getStatData() {
      try {
        const url = '/admin/projects/deposits/analytics/digits'
        const response = await apiClient.get(url, {
          params: {
            project_id: this.projectId,
            from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
            to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
            search: this.searchValue,
          },
        })
        this.statData = response.data.data
      } catch (e) {
      }
    },
    refreshList() {
      this.fetch({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        project_id: this.projectId,
        from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
        search: this.searchValue,
      })
    },
    async updateTable(value) {
      this.pagination.current = 1
      this.refreshList()
      await this.getStatData()
    },
    showComment(comment) {
      const h = this.$createElement
      this.Modal.info({
        title: 'Comment for operation',
        content: h('div', {}, [
          h('p', comment),
        ]),
        onOk() {
        },
      })
    },
    showTransfer(transferInfo) {
      this.transferInfo = transferInfo
      this.transferModal = true
    },
    clipboardHandle(v) {
      // console.log(v)
      this.$clipboard(v)
      this.$notification.success({
        message: this.$t('messages.copiedToClipboard'),
        description: `${v.slice(0, 16)}..`,
      })
    },
  },
}
</script>

<style scoped>

</style>
